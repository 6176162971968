<template>
  <div class="h-100">
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      totalSteps: 0,
      tabs: [], // all of the tab
    };
  },
  computed: {},
  created() {
    this.tabs = this.$children;

    console.debug(this.tabs);
  },
  mounted() {
    this.totalSteps = this.tabs.length;
    this.selectSlide(0);
  },
  methods: {
    selectSlide(i) {
      this.selectedIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });

      this.$emit("index-changed", {
        selected: this.selectedIndex + 1,
        total: this.totalSteps,
      });
    },
    nextSlide() {
      if (this.selectedIndex  <= this.totalSteps) {
        this.selectSlide(this.selectedIndex + 1);
      }
    },
    prevSlide() {
      if (this.selectedIndex > 0) {
        this.selectSlide(this.selectedIndex - 1);
      }
    }
  },
};
</script>