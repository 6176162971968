<template>
  <div class="h-100">
   
    <b-row>
     <b-col cols="12">
     <b-aspect 
      :aspect="2.43"
      class="paymentTypes"
      >
      <img 
        src="https://s3.amazonaws.com/assets.mindfuli.com/images/lock-icon-4x.png" 
        class="center-offset-top"
      />
     </b-aspect>
     </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer, BTable, BAspect,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    BTable,
    BAspect,
  },
  data() {
    return {
      products: [
        {
          feature: '# of Peer Counselor Sessions',
          CC: 4,
          CTC: 2,
          TC: "<span class='font-125'>&#8734;</span>",
        },
        {
          feature: '# of Licensed Clinical Sessions',
          CC: '/',
          CTC: 2,
          TC: 4,
        },
        {
          feature: 'Cost Per Month',
          CC: '<span class="dollar">$</span>149',
          CTC: '<span class="dollar">$</span>249',
          TC: '<span class="dollar">$</span>349',
        },
        {
          feature: 'Progress Tracking',
          CC: "<span class='font-125'>&#10003;</span>",
          CTC: "<span class='font-125'>&#10003;</span>",
          TC: "<span class='font-125'>&#10003;</span>",
        },
      ],
      fields: [
        {
          key: 'feature',
          tdClass: 'text-left featureCol',
          thClass: 'mb-3',
        },
        {
          key: 'CC',
          thClass: 'mb-3',
        },
        {
          key: 'CTC',
          thClass: 'mb-3',
        },
        {
          key: 'TC',
          thClass: 'mb-3',
        },
      ],
    }
  },
  computed: {
    subHeading() {
      return 'Your payment is encrypted and you can change how you pay anytime. Cancel easily online.'
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";

.paymentTypes{
  background-color: #ffebe3 ;
  background-image: url('https://s3.amazonaws.com/assets.mindfuli.com/images/logos-4x.png');
  background-size:contain;
  width:100%
}

.center-offset-top{
  height:4.4vw;
  width:4.4vw;
  margin-top:-4vw;
}


@media (min-width: (map-get($grid-breakpoints,xl))){
.center-offset-top{
  height:50px;
  width:50px;
  margin-top:-40px;
}
}

</style>
