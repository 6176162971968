<template>
  <div
    v-if="isActive"
    class="d-flex h-100 align-items-start flex-column"
  >
    <div class="w-100 text-center pt-md-3 pl-md-3 pr-md-3 pt-1 pl-1 pr-1">
      <h1 class="slideHeading">
        {{ title }}
      </h1>
      <h2
        v-if="hasSubtitle"
        class="slideSubHeading"
      >
        {{ subTitle }}
      </h2>
    </div>
    <div class="w-100 text-center pt-1 pb-1 mt-auto mb-auto">
      <slot />
    </div>
    <div class="text-center mt-auto px-1 mr-auto ml-auto footerText">
      <p>{{ footerText }}</p>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

export default {
  name: 'SlideTemplate',
  components: {
    BImg,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    footerText: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    hasSubtitle() {
      return !(this.subTitle === 'undefined')
    },
  },
  created() { },
}
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";

.slideHeading {
  font-size: 5.2vw;
  text-align: left;
  font-weight:300;
}

.slideSubHeading{
  font-size: 3vw;
  text-align: left;
  font-weight: 300;
  margin-bottom:1em;
}

.footerText{
   width:85%;
}

@media (min-width: (map-get($grid-breakpoints,xl))){
  .slideHeading{
    font-size:62px;
  }

  .slideSubHeading{
    font-size:30px;
    margin-bottom:1em;
  }
}

@media (max-width: (map-get($grid-breakpoints,md))){
  .slideHeading{
    font-size: max(6.6vw,18px);
  }

  .slideSubHeading{
    font-size:max(3vw,14px);

  }

  .footerText{
    width:90% !important;
    font-size: .8em;
  }
}

</style>
