<template>
  <div class="h-100">
    <b-row>
      <b-col cols="12" class="align-center">
        <div class="bulletWrapper">
       <div v-for="bullet in bullets" class="checkedBullet">
          <span class='orange'>&#10003;</span>
          <span> {{ bullet }}</span>
       </div>
       </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer, BTable, BAspect,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    BTable,
    BAspect,
  },
  data() {
    return {
      bullets: [
            "12+ Years of Age",
            "California Resident for Clinical Therapy",
            "US Resident for Counseling (sub-clinical)",
            "Ready to Transform Your Life"

      ],
    }
  },

}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";

.checkedBullet{
    padding-top:.5em;
    font-size: max(2.5vw,16px);
    text-align: left;
}

.bulletWrapper{
    width:66%;
    margin-left:auto;
    margin-right:auto;
}

@media (min-width: (map-get($grid-breakpoints,xl))) {
 .checkedBullet{
    font-size: 30px;
 }
}

</style>
