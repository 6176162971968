<template>
  <div />
</template>

<script>
export default {
  name: 'ChatUnsubscribed',
  mounted() {
    window.Intercom('boot', {
      app_id: 'rk7nykrr',
      vertical_padding: 50,
    })
  },
  destroyed() {
    window.Intercom('hide')
  },
}
</script>

<style scoped>

</style>
