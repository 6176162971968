<template>
  <div v-if="!isMobile" class="h-100 productTable">
    <b-table :items="products" :fields="fields">
      <template #head(feature)="data">
        <div class="bg-white" />
      </template>
      <template #head(CC)="data">
        <b-aspect :aspect="aspect" class="productBox bg-success">
          <h2 class="align-middle">Counselor Connect</h2>
        </b-aspect>
      </template>
      <template #head(CTC)="data">
        <b-aspect :aspect="aspect" class="productBox bg-info">
          <h2 class="align-middle">Care Team Connect</h2>
        </b-aspect>
      </template>
      <template #head(TC)="data">
        <b-aspect :aspect="aspect" class="productBox bg-primary">
          <h2 class="align-middle">Therapist Connect</h2>
        </b-aspect>
      </template>
      <template #cell(CC)="data">
        <span v-html="data.value" />
      </template>
      <template #cell(CTC)="data">
        <span v-html="data.value" />
      </template>
      <template #cell(TC)="data">
        <span v-html="data.value" />
      </template>
    </b-table>
  </div>
  <div v-else class="h-100 mobileProductTable">
    <b-row class="mb-1">
      <b-col cols="4">
        <div class="productBoxMobile bg-success">
          <h2 class="align-middle">Counselor Connect</h2>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="productBoxMobile bg-info">
          <h2 class="align-middle">Care Staff Connect</h2>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="productBoxMobile bg-primary">
          <h2 class="align-middle">Therapist Connect</h2>
        </div>
      </b-col>
    </b-row>
    <div v-for="product in products">
      <b-row>
        <b-col cols="12">
          <span class="featureText">{{ product.feature }}</span>
        </b-col>
      </b-row>

      <b-row class="contentText border-bottom">
        <b-col cols="4">
          <span v-html="product.CC"/>
        </b-col>
        <b-col cols="4">
           <span v-html="product.CTC"/>
        </b-col>
        <b-col cols="4">
            <span v-html="product.TC"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BContainer, BTable, BAspect } from "bootstrap-vue";

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    BTable,
    BAspect,
  },
  data() {
    return {
      products: [
        {
          feature: "# of Peer Counselor Sessions",
          CC: 4,
          CTC: 2,
          TC: "<span class='font-125'>&#8734;</span>",
        },
        {
          feature: "# of Licensed Clinical Sessions",
          CC: "/",
          CTC: 2,
          TC: 4,
        },
        {
          feature: "Cost Per Month",
          CC: '<span class="dollar">$</span>149',
          CTC: '<span class="dollar">$</span>249',
          TC: '<span class="dollar">$</span>349',
        },
        {
          feature: "Progress Tracking",
          CC: "<span class='font-125 orange'>&#10003;</span>",
          CTC: "<span class='font-125 orange'>&#10003;</span>",
          TC: "<span class='font-125 orange'>&#10003;</span>",
        },
      ],
      fields: [
        {
          key: "feature",
          tdClass: "text-left featureCol",
          thClass: "mb-3",
        },
        {
          key: "CC",
          thClass: "mb-3",
        },
        {
          key: "CTC",
          thClass: "mb-3",
        },
        {
          key: "TC",
          thClass: "mb-3",
        },
      ],
      aspect: "16:9",
      isMobile: false,
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";

.productBox {
  //height: 8.2vw;
  width: 12.3vw;
  border-radius: 0 1.2em 1.2em 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  font-size: 1.166vw;
  padding: 0.2em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em !important;
}

.productBoxMobile {
  width: 100% !important;
  height: 4em;
  border-radius: 0 0.5em 0.5em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.productBoxMobile h2 {
  font-size: 1.1em;
  font-weight: bold;
  color: white;
  text-transform: none;
}

.productBox h2 {
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  text-transform: none;
}

.productTable {
  padding: 0.3em;
  font-size: 1.166vw;
  margin-right: 2em;
  margin-left: 2em;
  //width:58.3vw;
}

.mobileProductTable {
  padding: 0.3em;
  margin-right: 1em;
  margin-left: 1em;
}

.productTable table {
  width: 100%;
  font-size: 16px;
  font-weight: bolder;
}

.productTable th {
  padding: 0.3em 0.3em;
  background-color: white !important;
  border: none;
  border-bottom: none !important;
}

.productTable td {
  padding: 0.3em 0.3em;
  background-color: white !important;
  border: 1px solid 1px solid #ebe9f1;
}

.productTable tr:first-child td {
  border: none !important;
}

.featureCol {
  font-size: 13px;
  font-weight: bolder;
}

.dollar {
  font-size: 50%;
  position: relative;
  top: -0.6em;
}

.font-125 {
  font-size: 150%;
  font-weight: normal;
}

.featureText{
  color:grey;
  font-weight:300;
}

.contentText{
  font-weight:bold;
}

.orange {
  color: #f36c3f !important;
}

.bottomBorder{
  border-bottom:2px;
}

@media (min-width: (map-get($grid-breakpoints,xl))) {
  .productBox {
    //height: 100px;
    width: 1650px;
    border-radius: 0 1.2em 1.2em 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em !important;
    border-top-right-radius: 16.7px;
    border-bottom-right-radius: 16.7px;
    border-bottom-left-radius: 16.7px;
  }

  .productBox h2 {
    font-size: 18px;
    font-weight: bold;
    color: white;
  }

  .productTable {
    padding: 0.3em;
    font-size: 14px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 788px;
  }

  .productTable th {
    padding: 0.3em 0.3em;
    background-color: white !important;
    border: none;
  }
  .productTable td {
    padding: 0.3em 0.3em;
    background-color: white !important;
    border: 1px solid 1px solid #ebe9f1;
  }
}

@media (max-width: (map-get($grid-breakpoints, md))) {
  .productBox {
    //height: 8.2vw;
    width: 13.3vw;
    min-width: 85px;
    padding: 0.2em;
    font-weight: normal;
    margin-bottom: 2em !important;
  }
}

@media (max-width: (map-get($grid-breakpoints, sm))) {
  .productBox h2 {
    font-size: 10px;
  }
}
</style>
