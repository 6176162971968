<template>
  <div class="h-100">
    <b-row>
      <b-col cols="12" class="align-center">
        <div class="pl-3 pr-3 leftWrapper">
       <div v-for="bullet in bullets" class="checkedBullet">
        <li>{{ bullet.heading}}</li>
        <span>{{ bullet.subHeading }}</span>
       </div>
       </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer, BTable, BAspect,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BContainer,
    BTable,
    BAspect,
  },
  data() {
    return {
      bullets: [
            {
            heading: "Answer a few more questions.",
            subHeading: "Help us get to know you a little better."
            },
            {
            heading: "Get paired with your therapist or counselor in real time.",
            subHeading: "Select from a small list of recommendations we personalize for you"
            },
            {
            heading: "Select a Time to Meet.",
            subHeading: "You can begin meeting in as little as 48 hours."
            }
           
      ],
    }
  },

}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";

.paymentTypes{
  background-color: #f8d5c7 ;
  background-image: url('https://s3.amazonaws.com/assets.mindfuli.com/images/logos-4x.png');
  background-size:contain;
  width:100%
}

.checkedBullet{
    padding-top:.5em;
   
    text-align: left;
}

.leftWrapper{
    
}

.leftWrapper li{
    font-size: max(2.5vw,16px);
    font-weight:400;
}

.leftWrapper span{
    font-size: max(2vw,14px);
    color:rgb(181, 181, 181);
    padding-left:1.7em;
}

@media (min-width: (map-get($grid-breakpoints,xl))){
.leftWrapper li{
    font-size: 30px !important;
    font-weight: 400 !important;
}

.leftWrapper span{
    font-size: 24px;
    color:rgb(181, 181, 181);
    padding-left:1.7em;
}
}

</style>
