<template>
  <div>
    <slide-container >
      <slide-template ref="slides" title="Hi there. Let's finish setting you up">
        <b-img
          fluid
          src="https://mindfuli.com/wp-content/uploads/2022/08/Mindfuli-Hero-1_1660244512.334787_optimized.webp"
          class="w-50"
        />
      </slide-template>
      <slide-template ref="slides" title="Second Tab">
        <b-img
          fluid
          src="https://mindfuli.com/wp-content/uploads/2022/08/Mindfuli-Hero-1_1660244512.334787_optimized.webp"
          class="w-50"
        />
      </slide-template>
      <slide-template ref="slides" title="THird Tab">
        <b-img
          fluid
          src="https://mindfuli.com/wp-content/uploads/2022/08/Mindfuli-Hero-1_1660244512.334787_optimized.webp"
          class="w-50"
        />
      </slide-template>
    </slide-container>
  </div>
</template>

<script>
import {
  BCardTitle,
  BRow,
  BCol,
  BContainer,
  BSpinner,
  BCarousel,
  BCarouselSlide,
  BJumbotron,
  BImg,
  BButton,
  BTab,
  BTabs,
  BAspect,
  BProgress,
} from "bootstrap-vue";

import ChatUnsubscribed from "@/views/ChatUnsubscirbed.vue";
import SlideTemplate from "@/components/welcome/SlideTemplate.vue";
import SlideContainer from "@/components/welcome/SlideContainer.vue";

export default {
  name: "Welcome",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    SlideTemplate,
    SlideContainer,
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables/_variables.scss";
@import "./src/@core/scss/base/bootstrap-extended/include";
@import "./src/@core/scss/vue/pages/page-auth.scss";
@import "./src/@core/scss/vue/libs/vue-wizard.scss";
@import "./src/@core/scss/vue/libs/vue-select.scss";
@import "./src/@core/scss/vue/pages/page-pricing.scss";

@media (min-width: (map-get($grid-breakpoints, lg))) {
  .border-right-lg {
    border-right: 1px solid $border;
  }
}

@media (min-width: (map-get($grid-breakpoints, md))) {
  .border-right-md {
    border-right: 1px solid $border;
  }
}

@media (min-width: (map-get($grid-breakpoints, sm))) {
  .border-right-sm {
    border-right: 1px solid $border;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .w-sm-fit-content {
    width: fit-content;
  }
}

.top-banner {
  width: 100%;
  padding: 1.5rem;
}
.bg-blue {
  background-color: #c1d3df;
}
.bg-orange {
  background-color: #fad0be;
}

.carousel-inner {
  min-height: 100% !important;
}

html {
  font-size: 1.5vw;
}
</style>